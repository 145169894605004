.user-info-container {
  .list-desc  {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 120px;
  }

}
.add-user-container{
  .add-form {
    //min-height: 900px;
    padding: 24px;
    margin: 0 24px 24px;
    background: #FFFFFF;
    .ant-form-item-label{
      width:90px;
      > label {
        color: #4A4A4A;
        justify-content: flex-start;
      }
    }
    .name-input {
      width:540px;
      height: 32px;
    }
    .item-input {
      width:252px;
      height: 32px;
    }
    .add-select {
      width: 164px;
      height: 32px;
    }
    .add-sub-select{
      .ant-form-item-label > label::before {
        content: '';
      }
    }
    .ant-form-item-label > label::after {
      content: ' ';
    }
    .footer-btn {
      margin-left: 80px;
      .submit-btn {
        width: 96px;
        margin-top:0;
      }
    }
  }
}
.detail-user-container {
  .detail-btn-text {
    font-size: 14px;
    color:#1890FF;
    cursor: pointer;
  }
  .password {
    display: flex;
  }
  .show {
    margin-left: 20px;
    cursor: pointer;
  }
  .disable {
    .ant-radio-inner {
      background-color: #d9d9d9;
    }
  }
  .using {
    .ant-radio-inner {
      background-color: rgba(24, 144, 255, 0.25);
      border-color:transparent !important;
    }
    .ant-radio-disabled .ant-radio-inner::after {
      background-color: #1890FF;
    }
  }
}

.password-form {
  background: #FFFFFF;
  padding-top: 24px;
  .ant-form-item-label {
    width: 90px;
    margin-left: 120px;
    flex-grow: 0;
  }
  .ant-form-item-label > label {
    color: #4A4A4A;
    justify-content: flex-start;
  }
  .ant-form-item-label > label::after {
    content: ' ';
  }
  .ant-form-item-control-input {
    margin-right: 40px;
  }
  .footer-btn {
  padding-left: 210px;
}
  .password-input{
    width: 256px;
    height: 32px;
  }
}