body {
    background: #FFF;
}

html,
body {
    font-family: 'pingfang SC', 'helvetica neue', arial, 'hiragino sans gb', 'microsoft yahei ui', 'microsoft yahei', simsun, sans-serif
}

li,
ol {
    list-style: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

p {
    margin: 0;
}

@font-face {
    font-family: 'iconfont';
    src: url('../fonts/iconfont.eot');
    src: url('../fonts/iconfont.woff') format('woff'),
    url('../fonts/iconfont.ttf') format('truetype'),
    url('../fonts/iconfont.svg') format('svg');
}

.iconfont {
    font-family: iconfont, pingfang SC,helvetica neue, arial,hiragino sans gb,microsoft yahei ui,microsoft yahei,simsun,sans-serif !important;
    font-style: normal;
    -webkit-text-stroke-width: .2px;
    font-weight: 100;
}

.ant-layout {
    background: #FFF;
}

.ant-layout-content {
    background: #F1F1F6;
   // padding: 24px;
    min-height: initial;
    min-width: 1100px;
}

.container-shadow {
    background: #FFFFFF;
    box-shadow: 0 2px 16px 0 rgba(255, 231, 231, 0.5);
    border-radius: 3px;
}

.ant-form-item-label > label {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    position: relative;
    
    &::before {
        position: absolute;
        right: -4px;
    }
}

//.ant-input:hover {
//    border-color: #747474;
//
//}
//
//.ant-input:focus,
//.ant-input-focused {
//    border-color: #747474;
//    box-shadow: none;
//}

.ant-form-item-explain {
    font-size: 12px;
}

.content-title {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #e4e4e4;
    padding-left: 24px;
    
    .title-icon {
        width: 21px;
        margin-right: 16px;
    }
    
    .text {
        font-size: 18px;
        color: #000;
    }
}

//统一修改组件按钮样式
.submit-btn {
    height: 50px;
    color: #fff;
    font-size: 18px;
    border-radius: 100px;
    margin-top: 50px;
    padding: 0;
    border: none;
    
    &.btn-bg-white {
        background: #FFFFFF !important;
        
        &:hover {
            background-color: #FFFFFF !important;
        }
        
        &:active {
            background-color: #FFFFFF !important;
        }
    }
}

textarea {
    min-height: 86px;
    resize: none;
}

.ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(237, 76, 76, .08);
}

#loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    font-size: 20px;
    
    .ant-spin-dot-item {
    }
}

.ant-popover {
    max-width: 50%;
    
    .ant-popover-inner-content {
        word-break: break-all;
    }
}

.ant-form-item-label > label::after {
    //content: '';
}
//.ant-btn-primary:active {
//    background: #1890FF;
//    border-color: #1890FF;
//    box-shadow:none;
//}
//.ant-btn-primary:hover, .ant-btn-primary:focus {
//    background: #1890FF;
//    border-color: #1890FF;
//}
.ant-input-number:hover,.ant-input:hover {
    border-color: #1890FF;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    //color: #EA4335;
}
[ant-click-animating-without-extra-node='true']::after {
    box-shadow: none;
    opacity: 0;
}
//.ant-btn-primary {
//    text-shadow:none;
//    box-shadow:none;
//}
.page-title {
    height: 56px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #363B48;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    background: #FFFFFF;
    .title-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon-back {
            margin-right: 16px;
            cursor: pointer;
        }
    }
}
.container {
    padding: 24px;
    margin: 24px;
}
.last-right-row {
    margin-left: auto;
    text-align: right;
}
.last-row-input {
    .ant-input-affix-wrapper {
        border-radius: 31px;
        //height: 34px;
    }
}
.edit-switch-text {
    font-size: 14px;
    color: #747474;
    margin-left: 12px;
}
.search-item {
    .ant-btn-primary {
        margin-left: 8px;
        margin-right: 12px;
    }
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}
.btn-line {
    display: inline-block;
    height: 14px;
    border-right: 1px solid #efefef
}
.has-line-item {
    padding-left: 7px;
    border-left: 1px solid #efefef;
    display: inline-block;
    height: 14px;
    line-height: 14px;
    color: #1890FF;
    cursor: pointer;
    &.ant-btn-link {
        line-height: 4px;
    }
}
.ant-pagination-total-text {
    margin-right: auto;
    font-size: 14px;
    color: #B4B4B4;
    span {
        color: #1890FF;
    }
}
.ant-descriptions {
    .ant-descriptions-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 50px;
        color: #363B48;
        height: 50px;
        border-bottom: 1px solid rgba(228, 228, 228, 0.5);
    }
    .ant-descriptions-item-label {
        color: #666666;
    }
    .ant-descriptions-item-content {
        color: #4A4A4A;
    }
}
.table-data {
    padding: 24px;
    background: #FFF;
    margin: 0 24px 24px;
    .total-data {
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        color: rgba(47, 52, 56, 0.6);
        font-size: 14px;
    }
    .operate-btn {
        .ant-space-item {
            a {
                border-right: 1px solid rgba(47, 52, 56, 0.1);
                padding-right: 16px;
            }
            &:last-child {
                a {
                    border: 0;
                }
            }
        }
        .disabled-btn {
            color: gray;
            cursor: not-allowed;
            &:hover{
                color:gray;
            }
        }
    }
}
.page-total-data {
    display: flex;
    justify-content:space-between;
    margin-top: 23px;
    .pageStyle {
        float: right;
        // margin-right: 57px;
        li {
            width: 32px;
            height: 32px;
        }
        .ant-pagination-item:hover {
            border-color: #1890FF;
            a {
                color: #1890FF;
            }
        }
        .ant-pagination-item-active {
            background: #1890FF;
            border-color:#1890FF;
            &:hover {
                a {
                    color: #FFFFFF;
                }
            }
            a {
                color: #FFFFFF;
            }
        }
        .ant-pagination-options {
            display: none;
        }
    }
    .total-num {
        color: #1890FF;;
    }
}
.warning-dialog {
    .warning-content {
        text-align: center;
    }
    .account-modal-title {
        margin-top: 16px;
        //margin-bottom: 30px;
    }
    .ant-modal-footer {
        text-align: center;
        border: none;
        padding: 24px;
    }
    .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
        margin-left: 24px;
    }
    
}
.list-desc {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    width: 260px;
}

.search-select {
    .ant-col {
        max-width: calc(100% - 70px);
    }
    //max-width: 14vw;
}
.ant-input:focus, .ant-input-focused {
    box-shadow: none;
}
.ant-form-item-label > label::after {
    //display: none;
}