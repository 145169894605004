.certInfo {
  .search-box {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    padding: 32px 24px 24px;
    margin:0 24px;
    border-top: 1px solid rgba(47, 52, 56, 0.1);
    position: relative;
    bottom: -1px;
    .search-form {
      line-height: 30px;
      .search-title {
        margin-right: 12px;
        width:60px;
      }
      .search-input {
        width: 210px;
      }
      .search-select {
        width: 210px;
        margin-left: -30px;
      }
    }
    .search-input {
      .ant-input-affix-wrapper {
        width: 270px;
        height: 34px;
        padding: 0 11px;
        line-height: 34px;
        font-size: 16px;
        // color: #CCCCCC;
        background: #FFFFFF;
        border: 1px solid #E4E4E4;
        border-radius: 30px;
        cursor: pointer;
        .ant-input {
          height: 30px;
        }
      }
    }
  }
  .input-select {
    margin-top: 0;
    .ant-form-item-control-input {
      width: 164px;
      height:32px;
      position: relative;

      .suffix-text {
        position: absolute;
        right: -38px;
      }
    }
  }
  // 公用
  .header-navigation {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    height: 56px;
    line-height: 56px;
    margin-bottom: 24px;
    background: #FFFFFF;
    .breadcrumb {
      color: #363B48;
      font-size:18px;
      font-weight: 500;
    }
  }
  .detail-btn {
    min-width: 65px;
    height: 32px;
    padding: 5px 10px;
    background: #1890FF;
    margin-left: 8px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    font-weight:400;
    cursor: pointer;
    display: inline-block;
  }
  .reset-btn {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    color: #4A4A4A;
    font-weight: 500;
    margin-left: 20px;
    margin-right: 20px;
  }
  .reset-btn:hover, .reset-btn:focus {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
  }
  .breadcrumb-icon {
    margin-bottom: 4px;
    margin-right: 8px;
    &:active {
      color: #ffffff;
    }
  }
  .ant-btn:hover, .ant-btn:focus {
    //color: #FFFFFF;
    //border-color: #1890FF;
    //background: #1890FF;
  }
  .detail-card {
    margin: 24px;
    padding: 17px 24px 12px;
    background:#FFFFFF;
    min-height: 210px;
    .title {
      color: #363B48;
      font-weight: 500;
      font-size: 16px;
      border-bottom: 1px solid rgba(228, 228, 228, 0.5);
      padding-bottom: 12px;
      margin-bottom:24px;
    }
    .detail-row{
      margin-bottom: 24px;
      font-size: 14px;
      display: flex;
      .name-title {
        color:#666666;
        width: 70px;
        margin-right: 12px;
      }
      .name-text {
        color: #4A4A4A;
        width:80%;
        word-break: break-all;
      }
      .name-text-other {
        //display: flex;
        color: #1890FF;
        img {
          width: 13px;
          margin-right: 10px;
          margin-bottom: 3px;
        }
      }
    }
    .ant-table-cell {
      color: #4A4A4A;
      font-size: 14px;
    }
    .ant-table-tbody {
      .ant-table-cell {
        color: #747474;
      }
    }
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
  }
.footer-btn {
  text-align: left;
  }
  .dialog-footer-btn {
    text-align: right;
    margin-top: 40px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    height: 64px;
  }
  .submit-btn {
    width: 80px;
    height: 32px;
    margin-top: 20px;
    &:nth-child(1) {
      margin-right: 12px;
    }
    &:nth-child(2) {
      margin-right: 25px;
      margin-left:0;
    }
  }

  .cancel-btn {
    border: 1px solid #C38B3A;
    background: #ffffff;
    color: #C38B3A;
  }
  .ant-form-item-label > label {
    position: absolute;
  }
  .ant-form-item-label > label::before {
    position: absolute;
    right: -4px;
  }
  .modal-confirm {
    .ant-modal-mask {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}