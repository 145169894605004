@keyframes ups-and-downs {
    0% {
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(20px);
    }
}
@keyframes drop-down {
    0% {
        opacity: 0;
        transform: translateY(-70px);
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}